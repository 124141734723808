
import { useUserStore } from '@/stores/user';
import {useSettingStore} from "~/stores/setting";

export default defineNuxtRouteMiddleware((to, from) => {
    const userStore = useUserStore();
    const settingStore = useSettingStore();
    const token = userStore.getToken;

    if (token) {
        return navigateTo({
            path: settingStore.getHomeRouter
        });
    }
});
